/* IOS devices will auto zoom if input font size is < 16px */
input[type='text'],
input[type='number'],
input {
  font-size: 16px;
}

body{
/*Disables zooming (pinch and double tap) for IOS Devices*/
	touch-action: pan-x pan-y;
}