:root {
    --primary:#55bcec;
    --primary-main: var(--primary);
    --primary-light:#669bbf;
    --secondary-main:#243f72;
    --secondary-dark:#243f72;
    --info-main: rgba(26, 64, 100, 0.6);
    --warning: rgba(150,40,60,0.92);
    --error-main: #AE1E5A;
    --grey: #eaeaea;
    --background: var(--secondary-main);
    --background-paper: var(--grey);
    --background-inverted: rgb(219,192,141);
    --success: rgb(0, 255, 8);
    --text-primary: var(--grey);
    --text-secondary: var(--secondary-main);
}