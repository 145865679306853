html,
body,
#root {
	margin: 0;
	height: 100%;
	padding: 0;
}

p, button{
	font-size: 1em;
}
h1,
h2 {
	font-size: 25px;
	margin: 0;
	letter-spacing: 0.2px;
}

h1 {
	font-weight: 300;
}

h2 {
	font-weight: 500;
}

h4 {
	font-weight: 300 !important;
	font-size: 1.1em !important;
	margin: 0 !important;
}

h5 {
	font-weight: 300 !important;
	font-size: 1.3em !important;
	margin-bottom: 1em !important;
}

.cardSize {
	height: 60vh;
}

.rounded {
	border-radius: 40px !important;
}

.roundedBottom {
	border-radius: 0 !important;
	border-bottom-left-radius: 12px !important;
	border-bottom-right-radius: 12px !important;
}

.roundedTop {
	border-radius: 0 !important;
	border-top-left-radius: 12px !important;
	border-top-right-radius: 12px !important;
}

.roundedLeft {
	border-radius: 0 !important;
	border-top-left-radius: 12px !important;
	border-bottom-left-radius: 12px !important;
}

.roundedRight {
	border-radius: 0 !important;
	border-top-right-radius: 12px !important;
	border-bottom-right-radius: 12px !important;
}

.lonelyButton{
	width: 70%;
}

.shadow {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.glow {
	box-shadow: 0 0px 20px 0 rgb(255, 255, 255) !important;
}

.textShadow {
	text-shadow: 0px 0px 5px black;
}

.svgShadow {
	filter: drop-shadow(0px 0px 5px black);
}

.cardContent {
	padding: 0.5rem 1rem;
}

:root {
	--plyr-color-main: #55bcec;
}

.textAlignLeft {
	text-align: left;
}

.textAlignCenter {
	text-align: center;
}