/*Animations*/
.bouncingAnimation {
	animation: bouncing 1s infinite;
}

.flyInAnimation {
	animation: fly-in 0.2s;
}

.flyInAnimationBottom {
	animation: fly-in-bottom 0.5s;
}

.flyInAnimationTop {
	animation: fly-in-top 0.5s;
}

.flyOutAnimationBottom{
	animation: fly-out-bottom 3s;
	transform: translateY(100vh);
}

.pressedAnimation {
	animation: pressed 0.15s;
}

.fadeInAnimation {
	animation: fadeIn 0.5s;
}

.growXAnimation {
	animation: growX 0.5s;
}

.growAnimation {
	animation: grow 0.2s;
}

.popAnimation {
	animation: pop 0.5s;
}

.slideFromTopAnimation{
	animation: slideFromTop 0.5s;
}

.spinAnimationFast{
	animation: spin 2s linear infinite;
}

.spinAnimation{
	animation: spin 4s linear infinite;
}

.spinAnimationCounter{
	animation: spinCounter 4s linear infinite;
}

.spinAnimationCounterSlow{
	animation: spinCounter 30s linear infinite;
}

.pulseAnimation{
	animation: pulse 1.5s infinite;
}

.opacityPulseAnimation{
	animation: opacityPulse 1.5s infinite;
}

.opacityPulseFastAnimation{
	animation: opacityPulse 0.3s infinite;
}

.attentionAnimation{
	animation: opacityPulse 0.2s 2;
}

.levitateAnimation{
	animation: levitate 1s infinite;
}

@keyframes bouncing {

	0% {
		transform: translateY(3px);
	}

	20% {
		transform: translateY(-3px) rotate(5deg);
	}

	50% {
		transform: translateY(3px) rotate(0deg);
	}

	70% {
		transform: translateY(-3px) rotate(-5deg);
	}

	100% {
		transform: translateY(3px) rotate(0deg);
	}
}

@-webkit-keyframes bouncing {

	0% {
		-webkit-transform: translateY(3px);
	}

	20% {
		-webkit-transform: translateY(-3px) rotate(5deg);
	}

	50% {
		-webkit-transform: translateY(3px) rotate(0deg);
	}

	70% {
		-webkit-transform: translateY(-3px) rotate(-5deg);
	}

	100% {
		-webkit-transform: translateY(3px) rotate(0deg);
	}
}

.bouncing>div:nth-child(2) {
	animation-delay: 0.1s;
}

.bouncing>div:nth-child(3) {
	animation-delay: 0.2s;
}

@keyframes fly-in {
	from {
		transform: translateX(-100vw);
	}
}

@-webkit-keyframes fly-in {
	from {
		-webkit-transform: translateX(-100vw);
	}
}

@keyframes fly-in-bottom {
	from {
		transform: translateY(100vw);
	}
}

@-webkit-keyframes fly-in-bottom {
	from {
		-webkit-transform: translateY(100vw);
	}
}

@keyframes fly-in-top {
	from {
		transform: translateY(-100vw);
	}
}

@keyframes fly-out-bottom {
	from {
		transform: translateY(0);
	}
}

@keyframes pressed {
	0% {
		transform: scale(100%)
	}

	50% {
		transform: scale(80%);
	}

	100% {
		transform: scale(100%)
	}
}

@keyframes fadeIn {
	from {
		opacity: 0%;
	}
}

@keyframes growX {
	from {
		width: 0%;
	}
}

@keyframes grow {
	from {
		transform: scale(25%);
	}
}

@keyframes pop {
	0%{
		transform: scale(0%);
	}
	25%{
		transform: scale(300%);
	}
	50%{
		transform: scale(50%);
	}
	100%{
		transform: scale(100%);
	}
}

@keyframes slideFromTop {
	0%{
		z-index: -5;
		opacity: 1;
		transform: translateY(-3em);
	}
	100%{
		z-index: -5;
		opacity: 1;
	}
}


@keyframes spin {
	from{
		transform: rotate(-360deg);
	}
}

@keyframes spinCounter {
	from{
		transform: rotate(360deg);
	}
}

@keyframes pulse{
	0% {
		transform: scale(20%);
	}
	40%{
		transform: scale(100%);
	}
	60%{
		transform: scale(100%);
	}
	100%{
		transform: scale(80%);
	}
}

@keyframes opacityPulse {
	0% {
		opacity: 0.4;
	}
	40%{
		opacity: 1;
	}
	60%{
		opacity: 1;
	}
	100%{
		opacity: 0.4;
	}
}

  @keyframes fadeIn{
	  from{
		  opacity: 0%;
	  }
  }

  @keyframes levitate{
	  0%{
		  transform: translateY(0px);
	  }
	  30%{
		  transform: translateY(-10px);
	  }
	  100%{
		  transform: translateY(0px);
	  }
  }

  @keyframes inSpace{
	0%{
		transform: translateY(0px) translateX(0px) scale(100%);
		-webkit-transform: translateY(0) translateX(0) scale(1);
	}
	15%{
		transform: translateY(-5px) translateX(-2px) scale(90%);
		-webkit-transform: translateY(-5px) translateX(-2px) scale(0.9);

	}
	30%{
		transform: translateY(5px) translateX(2px) scale(90%) rotate(45deg);
		-webkit-transform: translateY(5px) translateX(2px) scale(0.9) rotate(45deg);

	}
	45%{
		transform: translateY(5px) translateX(-2px);
		-webkit-transform: translateY(5px) translateX(-2px);
	}
	60%{
		transform: translateY(-5px) translateX(2px) scale(90%) rotate(-45deg);
		-webkit-transform: translateY(-5px) translateX(2px) scale(90%) rotate(-45deg);
	}
	100%{
		transform: translateY(0px) translateY(0px);
		-webkit-transform: translateY(0px) translateY(0px);

	}
}

  @keyframes warpFromCenter {
	  0%{
		  transform: scale(0%) translateY(-100vh);
		  -webkit-transform: scale(0.01) translateY(-2000em);
	  }
	  60%{
		  transform: scale(110%);
		  -webkit-transform: scale(1.1) translate(0);

	  }
	  100%{
		transform: scale(100%);
		-webkit-transform: scale(1) translate(0);
	  }
	  	
  }
