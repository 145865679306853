.formField {
	height:3rem !important;
	width: 100%;
	border-radius: 30px;
}

input.formField, select.formField{
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
    background-color: var(--primary-light);
    color:  var(--background-paper);
    padding-left: 1.2rem;
}

.formWarning {
	font-size: 1rem;
	color: red;
}

.codeText {
	text-transform: uppercase;
	font-family: 'Roboto Mono', Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif !important;
	font-weight: 500 !important;
}

.formText{
    font-weight: 700;
    font-family: 'Open Sans';
    font-size: 1rem;
}

.labelText{
    font-weight: 500;
    font-family: 'Open Sans';
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.formSelect{
    -webkit-appearance: none;
}

/* This is here to allow stying of a select on ios*/
.selectWrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.selectWrapper:after {
    content: '\0025BC';
    font: normal normal normal 12px/1 FontAwesome;
    color: var(--background-paper);
    right: 14px;
    top: 4px;
    height: 26px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}