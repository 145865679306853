.roundedPictureFrame{
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border: solid;
}

.roundImage{
    border-radius: 50%;
    object-fit: cover;
}