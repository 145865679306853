@import "./Forms.css";

.chooseNickname{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.chooseNickname form{
    width: 100%;
    height: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    --paddingY: 4em;
    padding-top: var(--paddingY);
    padding-bottom: var(--paddingY);
    --paddingX: 1em;
    padding-left: var(--paddingX);
    padding-right: var(--paddingX);
}

.chooseNickname h1{
    font-weight: 600;
    margin-bottom: 1em;
}

.chooseNickname .spinner{
    height: 100%;
    display: flex;
    align-items: center;
}