.cookieBannerContainer{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: flex-end;
}

.cookieBanner{
    background-color: var(--background-paper);
    color: var(--secondary-main);
    min-height: 6em;
    width: 100%;
    display: flex;
    padding-top: 0.5em;
    align-items:flex-start;
    justify-content: center;

}

.cookieBanner p{
    font-size: 0.65em;
    margin: 0;
}

.cookieBanner .left, .cookieBanner .right{
    height: 100%;
    padding: 0.5em;
    display: flex;
    justify-content: center;
}

.cookieBanner .ltButton{
    width: 12em;
    height: 3em;
    font-size: 0.8rem;
    background-color: var(--primary-main);
    color: var(--background-paper);
    border: none;
}

.cookieBanner .ltButton .fa-cookie{
    font-size: 1.3em;
}

.cookieBanner .buttonContent{
    display: flex;
    align-items: center;
}

@media screen and (max-width:290px) {
    .cookieBanner{
        flex-direction: column;
    }
}
