.earthFooter{
    position: fixed;
    z-index: -2;
    bottom: -15vw;
    width: 100%;
}

@media  (max-height:510px) {
    .earthFooter{
        bottom: -35vw;
    }
}

@media (orientation: landscape) {
    .earthFooter{
        bottom: -50vw;
    }
}
