.fa{
    font-size: 1.2rem;
}

/*
-This is not the real thin font weight of font awesome
-We use this trick to get thin icons for free
-The webkit-text-stroke color must be set to the background color,
because we use the text stroke as a 'mask'
*/

.fa-solid.ltThin{
    color: transparent;
    -webkit-text-stroke: 0.1rem var(--background-paper);
}

.fa-solid.ltThin.lightBackground{
    -webkit-text-stroke: 0.1rem var(--background);
}

.fa-solid.ltThin.darkBackground{
    -webkit-text-stroke: 0.1rem var(--background-paper);
}

.fa-regular.ltThin{
    -webkit-text-stroke: 0.07rem var(--background);
}

.fa-regular.ltThin.lightBackground{
    -webkit-text-stroke: 0.07rem var(--background-paper);
}

.fa-regular.ltThin.darkBackground{
    -webkit-text-stroke: 0.07rem var(--background-paper);
}

.ltButton.idle .fa-regular.ltThin{
    -webkit-text-stroke-color: var(--background-paper);
}

.ltButton.ltButton.selected .fa-regular.ltThin{
    -webkit-text-stroke-color: var(--background);
}

.ltButton:active .fa-solid.ltThin{
    -webkit-text-stroke-color: var(--background-paper);
}