@import "./Forms.css";


.chooseAvatars{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-image: url("../../../assets/stars.jpg");
    background-size:contain;
    background-repeat: repeat;
    animation: fadeIn 1s linear;
}

.chooseAvatars h1{
    text-align: center;
    font-weight: 600;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}
.chooseAvatars .avatarCloud{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em;
    margin-bottom: 40vh;
}

.avatarCloud .avatarButton{
    transition: all 0.5s;
}

.avatarEarthFooter{
    z-index: 0;
}

.avatarFooter{
    z-index: 0;
    -webkit-transform: translateZ(0);
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.avatarFooter .ltButton{
    width: 80%;
    margin-bottom: 1em;
}