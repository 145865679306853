.ltButton{
    --button-width: 100%;
    width: var(--button-width);
    min-height: 2.1em;
    height: fit-content;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: relative;
    font-family: 'Open Sans';
}

.ltButton.idle{
    background-color: var(--background-paper);
    color: var(--background);
    border-color: var(--background);
    border-style: solid;
    border-width: 1px;
}

.ltButton.selected{
    background-color: var(--secondary-main);
    color: var(--background-paper);
    border-color: var(--background-paper);
    border-style: solid;
    border-width: 1px;
}

.ltButton.disabled{
    opacity: 30%;
}

.ltButton.progress{
    background-color: var(--secondary-main);
    color: var(--background);
    border-color: var(--background);
    border-style: solid;
    border-width: 1px;
}

.ltButton.idle:active:not(.disabled):not(.selected) {
    transform: scale(97%);
    background-color: var(--secondary-main);
    color: var(--background-paper);
    border-color: var(--background-paper);
    border-style: solid;
    border-width: 1px;
}

.ltButton .progressBar{
    position: absolute;
    left: 0;
    background-color: var(--primary-main);
    border-radius: 30px;
    animation: growX 1s;
}

.ltButton .children{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1.5em;
}

.ltButton.progress .children{
    color: var(--background-paper);
    text-shadow: 0px 0px 2px black;
}