@import "./Forms.css";

.codeRegistration{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.codeRegistration form{
    width: 100%;
    height: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    --paddingY: 4em;
    padding-top: var(--paddingY);
    padding-bottom: var(--paddingY);
    --paddingX: 1em;
    padding-left: var(--paddingX);
    padding-right: var(--paddingX);
}

.codeRegistration form .top{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.codeRegistration h1{
    font-size: 1.2em;
    font-weight: 600;
    text-transform: uppercase;
}
