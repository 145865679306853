.startPage{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.startPage .globe{
    image-rendering: crisp-edges;
    width: 1000px;
    transform: scale(200%) translateX(-18.5%);
    -webkit-transform: scale(2) translateX(-18.5%);
    animation: globeAni 1s;
    -webkit-animation: globeAni 1s;
    
}

.startPage .globe.globeAfterClicked{
    transition: transform 0.7s linear;
    transform: translateX(-200%);
}

@media (orientation:landscape) {
    .startPage .globe{
        transform: scale(120%) translateX(-18.5%);
    }
}

.triangleButton{
    animation: pulseButton 1s infinite;
}

@keyframes globeAni {
    from{
        transform: scale(5%);
        -webkit-transform: scale(0.05);
    }
}

@-webkit-keyframes globeAni {
    from{
        -webkit-transform: scale(0.05);
    }
}

@keyframes pulseButton{
    0%{
        opacity: 100%;
    }
    50%{
        opacity: 50%;
    }
    100%{
        opacity: 100%;
    }
}

@-webkit-keyframes pulseButton{
    0%{
        -webkit-opacity: 100%;
    }
    50%{
        -webkit-opacity: 50%;
    }
    100%{
        -webkit-opacity: 100%;
    }
}